<script>
import { GroupPermission } from '@/enums';

const TABS = [
  {
    label: 'Profil',
    route: 'edit',
    icon: 'person'
  },
  {
    label: 'Schimbă Parola',
    route: 'change_password',
    icon: 'password'
  },
  {
    label: 'Overview',
    route: 'overview',
    icon: 'analytics'
  },
  {
    label: 'Task-uri Periodice',
    route: 'recurring_task_edit',
    icon: 'event_repeat'
  },
  {
    label: 'Administrează',
    route: 'manage',
    icon: 'manage_accounts',
    admin_only: true
  },
  {
    label: 'Notificări',
    route: 'notifications_settings',
    icon: 'edit_notifications',
    private: true
  }
]


export default {
  name: 'AccountSettings',

  data() { return {
    userData : {},
    route: "edit",
    waitResponse: true
  }},

  created() 
  {
    this.RequestUserData();
  },

  watch: {
    userId() {
      this.RequestUserData();
    }
  },

  computed: {
    userId() 
    {
      return this.$route.params.id;
    },

    tabs() 
    {
      return TABS
        .filter(tab => {
          return (!tab.admin_only || this.$session.HasAccess([GroupPermission.MANAGE_USERS]));
        })
        .filter(tab => {
          return (!tab.private || this.$session.userId == this.userId);
        });
      }
  },

  methods: {
    RequestUserData()
    {
      if(this.userId == null) {
        return;
      }

      this.waitResponse = true;

      this.$api.get({ path: `users/${this.userId}` }, this.OnRecieveUserData);
    },

    OnRecieveUserData({response})
    {
      const { user } = response.data;

      this.UpdateUserData(user);

      document.title = `${this.$const.title} - ${user.f_name} ${user.s_name}`;
      this.waitResponse = false;
    },

    UpdateUserData(data)
    {
      this.userData = data;
    },

    RemoveAvatar() 
    {
      this.$pop.question('Șterge Avatar', 'Ești sigur că vrei să ștergi avatarul?', () => this.ConfirmRemoveAvatar())
    },

    ConfirmRemoveAvatar()
    {
      this.$api.post({ path: `users/${this.userId}:remove-avatar` }, this.OnRemoveUserAvatar);
    },

    OnRemoveUserAvatar()
    {
      this.$msg.tpush('profile_picture_removed');
      this.userData.picture = "";

      if (this.$session.IsUser(this.userId)) {
        this.$session.user.SetPictureUrl("");
      }
    },

    GroupUpdated(group)
    {
      this.userData.ugroup = group.id;
      this.userData.group_name = group.name;
    },

    OnChangeRoute()
    {
      this.$router.push(this.route);
    },

    IsRouteSelected(route)
    {
      return this.route == route;
    },

    SelectedTabAttr(route)
    {
      return this.IsRouteSelected(route) ? "": null;
    },

    GoToTasksList()
    {
      this.$router.push(`/admin/user/${this.userId}/tasklist`);
    }
  },
  
};
</script>


<template>
  <div class="box profile-body" v-if="waitResponse">
    <loading-box></loading-box>
  </div>

  <div class="box profile-body" v-else>

    <div class="profile-panel">
      <group-picker class="user-group" :user-group="userData.ugroup" :user-target="userData.id" @group-update="GroupUpdated">
        <group-plate class="group-button" :id="userData.ugroup"></group-plate>
      </group-picker>

      <div class="profile-avatar">
        <avatar 
          :url="userData.picture" 
          :f-name="userData.f_name" 
          :s-name="userData.s_name" 
          :active="userData.is_active"
        />

        <div class="avatar-remove click-area" @click="RemoveAvatar">REMOVE</div>
      </div>

      <div class="info-box">
        <div class="user-name">
          {{userData.f_name}} {{userData.s_name}}
        </div>

        <div class="user-status">
          Online: {{this.$utils.TimeStampToString(userData.last_active)}}
        </div>

        <user-actions btn-type="bordered" :userData="userData"></user-actions>
      </div>

      <div v-if="!$utils.isMobile()" class="profile-nav">
        <button v-for="tab in tabs" :key="tab" class="no-bg ripple-effect"
          @mousedown="route = tab.route" 
          @mouseup="OnChangeRoute()"
          :selected="SelectedTabAttr(tab.route)"
        >
          <icon v-if="tab.icon">
            {{tab.icon}}
          </icon>
          {{tab.label}}
        </button>
      </div>

      <div v-else>
        <select name="profile-nav" class="input" @change="OnChangeRoute()" v-model="route">
          <option :value="tab.route" v-for="tab in tabs" :key="tab">{{tab.label}}</option>
        </select>
      </div>

    </div>

    <!-- view -->
    <router-view class="profile-view" :user-id="userId" v-model:user-data="userData" @update="UpdateUserData">
    </router-view>
  </div>
</template>

<style scoped>
.profile-body {
  position: relative;
  height: fit-content;
  padding: 0px !important;
  overflow: hidden;
  margin-bottom: 50px;
}

.profile-view {
  z-index: 1;
  animation: slide-right 0.3s;
  margin: 20px;
}

.profile-panel {
  position: relative;
  background-color: var(--box-color2);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  float: left;
  border-right: solid 1px var(--border-color);
  padding: 10px;
  width: 230px;
  /* height: 100%; */
  z-index: 2;
}


.profile-avatar {
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.profile-avatar .avatar-container {
  width: 100%;
  height: 100%;
}

.avatar-remove {
  border-radius: 50%;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.0;
  background-color: rgba(0, 0, 0, 0.664);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.avatar-remove:hover {
  opacity: 1.0;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
  border-top: var(--solid-border);
}

.profile-nav button {
  justify-content: left;
}

.profile-nav button[selected] {
  background-color: var(--box-color-blue);
  color: var(--theme-color);
}

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.info-box .user-status {
  font-size: 13px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  background-color: var(--dark-box-color);
  padding: 10px;
  border-radius: 50px;
  border: solid 1px var(--border-color);
}

.actions-container {
  margin-top: 20px;
}

.user-group {
  width: 100%;
  margin: 10px;
  padding: 0;
}

.group-button {
  width: 100%;
  height: 100%;
}

.group-button:active {
  transform: translateY(1px);
}

.user-name {
  font-size: large;
}

@media only screen and (max-width: 1000px) {
  .profile-body {
    height: fit-content;
  }
  
  .profile-view {
    display: block;
    margin: 0px;
    width: 100%; 
    padding: 20px;
  }

  .profile-panel {
    width: 100%;
    margin: 0px;
    height: fit-content;
    padding-bottom: 20px;
    border: none;
    border-bottom: solid 1px var(--border-color);
  }

  .profile-nav {
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    overflow: auto;
    gap: 2;
  }

}
</style>

<style>
.input-row {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 5px;
  text-align: right;
  justify-content: flex-end;
}

.input-row > input, .input-box {
  width: 300px;
  background-color: var(--input-bg-color);
}

.input-row > button {
  width: 300px;
}

.input-row > label {
  margin-right: 40px;
}

.config-row {
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: center;
  padding-block: 10px;
  font-weight: bold;
  border-bottom: var(--border-color) 1px solid;
  user-select: none;
  cursor: pointer;
  width: 100%;
  color: var(--text-color);
}

.config-row:hover {
  background-color: var(--dark-box-color);
}

.config-row:last-child {
  border: none;
}



@media only screen and (max-width: 1000px) {
  .input-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px;
  }

  .input-row label {
    margin: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .input-row .input-box {
    margin: 0px;
    font-size: 15px;
    width: 100%;
  }

}
</style>
